import React from "react"
import PropTypes from "prop-types"

import linkedInImg from './social_media/linkedin_icon_dark.svg'
import facebookImg from './social_media/facebook_icon_dark.svg'
import xingImg from './social_media/xing_dark.svg'
import instagramImg from './social_media/instagram_dark.svg'

const Profile = ({ img, name, role, linkedInUrl, facebookUrl, xingUrl, instagramUrl }) => {
  return (
    <div className="text-center p-10 md:p-0">
      <div className="mx-auto mb-4 bg-center bg-cover"
        style={{ backgroundImage: `url(${img})`, maxHeight: 230, maxWidth: 230, paddingTop: '100%', width: '100%' }} />
      <h3 className="text-gradient text-2xl">{name}</h3>
      <div className="font-light uppercase text-xs tracking-wider mb-2">{role}</div>
      <div className="flex justify-center">
        {linkedInUrl && <a href={linkedInUrl} className="hover:opacity-75 mx-2">
          <img src={linkedInImg} className="h-3" />
        </a>}
        {facebookUrl && <a href={facebookUrl} className="hover:opacity-75 mx-2">
          <img src={facebookImg} className="h-3" />
        </a>}
        {xingUrl && <a href={xingUrl} className="hover:opacity-75 mx-2">
          <img src={xingImg} className="h-3" />
        </a>}
        {instagramUrl && <a href={instagramUrl} className="hover:opacity-75 mx-2">
          <img src={instagramImg} className="h-3" />
        </a>}
      </div>
    </div>
  )
}

export default Profile
