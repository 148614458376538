import React from "react"

import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Header from "../components/about/header"
import Section1 from "../components/homepage/section3"
import Section2 from "../components/homepage/section4"
import Section3 from "../components/about/section3"
import Section4 from "../components/about/section4"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <Navigation isAbsolute={false} currentPage="about" inverted />
    <Header />
    <Section3 />
    <Section4 />
    <Section2 />
  </Layout>
)

export default AboutPage
