import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import femaleCofounderIcon from '../icons/female_founder_icon.svg'
import maleFounderIcon from '../icons/male_founder_icon.svg'
import startupIdeaIcon from '../icons/startup_idea_icon.svg'

const Section3 = ({}) => (
  <section className="container py-4 lg:py-32 xl2:py-40">
    <div data-sal="slide-right" data-sal-delay="200" data-sal-duration="500">
      <div className="text-sm text-gradient uppercase font-display font-bold">Zielgruppe</div>
      <h2 className="text-2xl lg:text-4xl leading-none font-display font-bold">An wen richtet sich die Initiative?</h2>
      <hr className="border-meteor-500 border-2 w-1/5 my-6" />
    </div>
    <div className="md:flex justify-center">
      <div className="text-center my-12 md:mx-8 md:w-1/3 rounded-xl py-12 px-4 border border-mulberry-500 flex flex-col" data-sal="slide-down" data-sal-delay="100" data-sal-duration="500">
        <div className="rounded-full h-24 w-24 md:h-32 md:w-32 bg-meteor-500 bg-gradient-l-mulberry-500 mx-auto p-4 md:p-6 text-center mb-2 md:mb-6">
          <img src={femaleCofounderIcon} className="h-full mx-auto" alt="Female Co-Founder" />
        </div>
        <h3 className="font-display text-gradient text-2xl" style={{ paddingBottom: 0 }}>Female Co-Founder</h3>
        <div className="mb-4">ohne eigene Idee</div>
        <p className="font-light leading-relaxed flex-grow">
          Du bist bereit dich einer bestehenden Startup-Idee anzuschließen oder diese gemeinsam mit deinem
          potentiellen Co-Founder (w/m/d) zu entwickeln.
        </p>
        <div class="text-center pt-8">
          <Link to="/co-founder" className="inline-block hover:opacity-75 px-6 py-2 rounded mt-4 text-white bg-meteor-500 bg-gradient-l-mulberry-500">
            Mehr dazu
          </Link>
        </div>
      </div>
      <div className="text-center my-12 md:mx-8 md:w-1/3 rounded-xl py-12 px-4 border border-mulberry-500 flex flex-col" data-sal="slide-down" data-sal-delay="300" data-sal-duration="500">
        <div className="rounded-full h-24 w-24 md:h-32 md:w-32 bg-meteor-500 bg-gradient-l-mulberry-500 mx-auto p-4 md:p-6 text-center mb-2 md:mb-6">
          <img src={startupIdeaIcon} className="h-full mx-auto" alt="Female Co-Founder" />
        </div>
        <h3 className="font-display text-gradient text-2xl" style={{ paddingBottom: 0 }}>Founder (m/w/d)</h3>
        <div className="mb-4">mit eigener Idee</div>
        <p className="font-light leading-relaxed flex-grow">
          Du (w/m/d) hast bereits eine eigene Startup-Idee oder  gegründet und suchst nach einem oder mehreren
          Female-Co-Founder(s) für dich oder dein Gründungsteam.
        </p>

        <div class="text-center pt-8">
          <Link to="/co-founder" className="inline-block hover:opacity-75 px-6 py-2 rounded mt-4 text-white bg-meteor-500 bg-gradient-l-mulberry-500">
            Mehr dazu
          </Link>
        </div>
      </div>
    </div>
  </section>
)

Section3.propTypes = {
}

Section3.defaultProps = {
}

export default Section3
