import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import backgroundImg from '../photos/homepage_stats.jpg'

const Section4 = ({}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            meetupData {
              membersCount,
              eventsCountLastYear
            }
          }
        }
      }
    `
  )
  const meetupData = site.siteMetadata.meetupData;

  return (
    <div style={{ backgroundImage: `url(${backgroundImg})` }} className="bg-cover bg-center">
      <section className="container py-8 md:py-12 lg:py-24 xl2:py-32">
        <div className="md:flex text-center text-white">
          <div className="md:w-4/12 xl:px-16 py-4">
            <div className="font-bold text-5xl" data-sal="zoom-in" data-sal-delay="300" data-sal-duration="500">{meetupData.membersCount}</div>
            <div className="font-light">Mitglieder in der Community</div>
          </div>
          <div className="md:w-4/12 xl:px-16 py-4">
            <div className="font-bold text-5xl" data-sal="zoom-in" data-sal-delay="300" data-sal-duration="500">{meetupData.eventsCountLastYear}</div>
            <div className="font-light">Events jährlich und deutschlandweit</div>
          </div>
          <div className="md:w-4/12 xl:px-16 py-4">
            <div className="font-bold text-5xl" data-sal="zoom-in" data-sal-delay="300" data-sal-duration="500">2017</div>
            <div className="font-light">Gründung der Initiative</div>
          </div>
        </div>
      </section>
    </div>
  )
}

Section4.propTypes = {
}

Section4.defaultProps = {
}

export default Section4
