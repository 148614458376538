import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Section4 = ({}) => (
  <div className="relative mb-16">
    <div className="rotated-bg absolute bg-vanilla-ice"></div>
    <div className="bg-vanilla-ice h-16 absolute w-full" style={ { bottom: "-4rem", zIndex: -1 } }></div>
    <section className="container py-12 md:py-20 lg:py-32 lg:pb-20 xl:py-40 xl:pb-24 xl2:py-40 xl2:pb-24">
      <div className="text-sm text-gradient uppercase font-display font-bold pt-8 md:pt-0">FAQ</div>
      <h2 className="text-2xl lg:text-4xl leading-none font-display font-bold">Questions & Answers</h2>
      <hr className="border-meteor-500 border-2 w-1/5 my-6" />

      <div className="lg:w-8/12 xl2:w-6/12 mx-auto">
        <div className="mt-20">
          <h3 className="font-display fond-bold text-gradient text-xl mb-3">
            Q: An wen richtet sich das Angebot von Female Co-Founders?
          </h3>
          <p className="font-light leading-relaxed">
            Unser Angebot richtet sich einerseits an Frauen, die gründen wollen, bereits
            an einer Startup-Idee arbeiten und dafür auf der Suche nach einer Co-Founderin
            sind sowie an Frauen, die noch keine eigene Idee haben, aber sich einer bestehenden
            Idee als Co-Founderin anschließen wollen. Andererseits unterstützen wir mit unserer
            Community männliche Gründer und männlich-dominierte Gründungsteams bei der Suche nach
            einer Mitgründerin - als alternativer Rekrutierungsweg.
          </p>
        </div>
        <div className="mt-16">
          <h3 className="font-display fond-bold text-gradient text-xl mb-3">
            Q: Was macht Female Co-Founders?
          </h3>
          <p className="font-light leading-relaxed">
            Female Co-Founders veranstaltet regelmäßig Events zur Vernetzung von Gründungsinteressierten
            in ganz Deutschland. Neben Workshops und Vorträgen ist unsere Pitch Night ein besonders beliebtes
            Eventformat. Begleitet wird unser Angebot von einer Online-Community, in der Mitglieder
            Co-Founder-Suchprofile veröffentlichen und sich zu Gründungsthemen austauschen. Zudem unterstützen
            wir Institutionen mit unserer Erfahrung im Vernetzen und Matchen von Gründer*innen und Gründungsinteressierten.
          </p>
        </div>
        <div className="mt-16">
          <h3 className="font-display fond-bold text-gradient text-xl mb-3">
            Q: Inwiefern richtet sich das Angebot auch an Männer?
          </h3>
          <p className="font-light leading-relaxed">
            Da wir an den Mehrwert von diversen Teams glauben, unterstützen wir gern
            männlichen Gründer und männlich-dominierte Gründungsteams, die das ebenso
            tun, bei der Suche nach einer passenden Co-Founderin. Auf unseren Pitch Nights
            können sie ihre Startup-Ideen vorstellen sowie unsere Online-Community nutzen,
            um ihre Co-Founder-Suchprofile zu teilen.
          </p>
        </div>
      </div>
    </section>
  </div>
)

Section4.propTypes = {
}

Section4.defaultProps = {
}

export default Section4
