import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Profile from '../profile'

import heike from '../photos/heike.jpg'
import jalyna from '../photos/jalyna.jpg'
import johanna from '../photos/johanna.jpg'
import janaf from '../photos/jana_falk.jpg'
import sarah from '../photos/sarah_holzhauer.jpg'
import anne from '../photos/anne_fuhrmann.jpg'
import katja from '../photos/katja_schneidereit.jpg'
import isabella from '../photos/isabella_carmone.jpeg'
import janika from '../photos/janika.jpeg'

const Section3 = ({}) => (
  <section className="container pt-16 lg:pt-16 xl:pt-64 text-center">
    <h2 className="text-2xl lg:text-4xl leading-none font-display font-bold">Unser Team</h2>
    <hr className="border-meteor-500 border-2 w-1/5 my-6 mx-auto" />
    <div className="md:flex md:justify-center">
      <div className="md:my-8 md:mx-4 md:w-1/3 lg:w-3/12">
        <Profile
          img={heike}
          name="Heike Gäbler"
          role="Gründerin & Leitung"
          linkedInUrl="https://www.linkedin.com/in/heikegaebler/"
          facebookUrl="https://www.facebook.com/heike.gaebler.14"
          xingUrl="https://www.xing.com/profile/Heike_Gaebler/cv"
          instagramUrl="https://www.instagram.com/heikimimilovi/"
        />
      </div>
      <div className="md:my-8 md:mx-4 md:w-1/3 lg:w-3/12">
        <Profile
          img={isabella}
          name="Isabella Carmone"
          role="Lead Community Management"
        />
      </div>
      <div className="md:my-8 md:mx-4 md:w-1/3 lg:w-3/12">
        <Profile
          img={janaf}
          name="Jana Falk"
          role="Social Media & Content Creation"
          linkedInUrl="https://www.linkedin.com/in/jana-falk-1b5154126/"
        />
      </div>
    </div>
    <div className="md:flex md:justify-center">
      <div className="md:my-8 md:mx-4 md:w-1/4 lg:w-3/12">
        <Profile
          img={anne}
          name="Dr. Anne Fuhrmann"
          role="Events & Research"
          linkedInUrl="https://www.linkedin.com/in/dr-anne-fuhrmann/"
        />
      </div>
      <div className="md:my-8 md:mx-4 md:w-1/4 lg:w-3/12">
        <Profile
          img={sarah}
          name="Sarah Holzhauer"
          role="Partnerschaften & Social Media"
          linkedInUrl="https://www.linkedin.com/in/sarah-holzhauer/"
        />
      </div>
      <div className="md:my-8 md:mx-4 md:w-1/3 lg:w-3/12">
        <Profile
          img={janika}
          name="Janika Kugel"
          role="Events"
        />
      </div>
    </div>
    <div className="md:flex md:justify-center">
      <div className="md:my-8 md:mx-4 md:w-1/4 lg:w-3/12">
        <Profile
          img={johanna}
          name="Johanna Ruhla"
          role="Design"
          xingUrl="https://www.xing.com/profile/Johanna_Ruhla/cv"
          linkedInUrl="https://www.linkedin.com/in/johanna-ruhla-b172a219b/"
        />
      </div>
      <div className="md:my-8 md:mx-4 md:w-1/4 lg:w-3/12">
        <Profile
          img={katja}
          name="Katja Schneidereit"
          role="Talent Management"
          linkedInUrl="https://www.linkedin.com/in/katjaschneidereit/"
        />
      </div>
      <div className="md:my-8 md:mx-4 md:w-1/4 lg:w-3/12">
        <Profile
          img={jalyna}
          name="Jalyna Schröder"
          role="Web Development"
          linkedInUrl="https://www.linkedin.com/in/jalyna-schr%C3%B6der-96427795/"
          facebookUrl="https://www.facebook.com/jalyna.schroder"
        />
      </div>
    </div>

    <div className="md:w-6/12 mx-auto pb-16 xl:pb-32 mt-12">
      <p className="font-bold text-xl">Wir suchen Unterstützung!</p>
      <p className="font-light leading-relaxed">Female Co-Founders sucht nach ehrenamtlichen Teammitgliedern, um noch besser
      und effektvoller für mehr Gender Diversity in Gründungsteams zu sorgen. Melde dich bei uns, wenn du Interesse
      hast! Wir freuen uns, von dir zu hören.</p>
      <div><a href="mailto:hallo@femalecofounders.de" className="inline-block hover:opacity-75 px-6 py-2 rounded mt-4 text-white bg-meteor-500 bg-gradient-l-mulberry-500">
        Mail
      </a></div>
    </div>
  </section>
)

Section3.propTypes = {
}

Section3.defaultProps = {
}

export default Section3
