import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import mobilePhoto from '../photos/about_mobile_header.png'
import photo from '../photos/about_header.png'

const Header = ({}) => (
  <div className="relative mb-16 bg-pampas" style={{ zIndex: -5 }}>
    <div className="rotated-bg absolute bg-pampas">
    
    </div>

    <div className="md:hidden">
      <img src={mobilePhoto} />
    </div>

    <div className="fc-header-wrapper cf hidden md:block">
      <div className="fc-header cf" style={{ backgroundImage: `url(${photo})` }}></div>
    </div>

    <div className="container py-8 md:py-12 xl:py-24 xl2:py-42">
      <div className="md:w-7/12">
        <div className="lg:text-lg tracking-wider text-gray-500 uppercase font-display font-bold mb-1 md:mb-4">About</div>
        <h1 className="text-4xl lg:text-6xl leading-none font-display font-bold text-gradient">Female-driven economy</h1>
        <hr className="bg-meteor-500 bg-gradient-l-mulberry-500 h-1 border-0 w-1/5 my-6" />
        <p className="font-light leading-relaxed">
          Female Co-Founders hat sich die aktive Vernetzung von Gründern und Gründerinnen zur Aufgabe gemacht, um sie
          zu weiblichen und diversen Gründungsteams zusammenzuführen. Wir setzen dabei auf die Förderung von
          Unternehmertum unter Frauen sowie die Integration von Frauen in bestehenden Gründungsteams.
        </p>
      </div>
    </div>
  </div>
)

Header.propTypes = {
}

Header.defaultProps = {
}

export default Header
